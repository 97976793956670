@tailwind base;
@tailwind components;
@tailwind utilities;

.MuiDataGrid-columnHeaders {
  background-color: #047857;
  color: white;
}

input:read-only {
  background-color: rgb(221, 221, 221);
}

select:disabled {
  background-color: rgb(221, 221, 221);
}

.myTable td {
  padding: 0.5rem;
  border: 1px solid grey;
}

.inputClickable {
  background-color: #fff !important;
}

.btn-footer-container {
  @apply flex items-center justify-between p-6 border-solid border-slate-200 rounded-b;
}

.save-btn {
  @apply px-6 py-2 p-1 font-bold border-emerald-700 border-2 bg-emerald-700 text-white uppercase rounded-md ease-linear transition-all duration-150 hover:text-emerald-700 hover:border-emerald-700 hover:bg-white disabled:opacity-75 disabled:hover:bg-emerald-700;
}

.close-btn {
  @apply px-6 py-2 p-1 font-bold text-white uppercase outline-none  rounded-md border-2 bg-red-500 border-red-500 ease-linear transition-all duration-150 hover:bg-white hover:text-red-500;
}

.new-btn {
  @apply w-1/3 p-2 bg-emerald-600 text-white rounded-md hover:scale-95 duration-150;
}

.delete-btn {
  @apply w-1/3 p-2 bg-red-600 text-white rounded-md hover:scale-95 duration-150;
}

.input-anagrafiche {
  @apply mt-0 block px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 pb-1 ml-8;
}
.btn-an-dsk {
  @apply w-1/3 xl:w-1/4 h-[150px] mt-1 p-4 bg-emerald-600 text-white text-xl font-bold rounded-md scale-95 hover:scale-90 duration-150;
}
.btn-an-mbl {
  @apply w-full h-24 p-4 m-2 bg-emerald-600 text-white text-xl font-bold rounded-md hover:scale-95 duration-150;
}

.input-ODL {
  @apply mt-0 block border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-emerald-700 w-3/5;
}

.MuiDataGrid-main {
  border-left: 1px solid rgba(224, 224, 224, 1) !important;
  border-right: 1px solid rgba(224, 224, 224, 1) !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.MuiDataGrid-footerContainer {
  border-left: 1px solid rgba(224, 224, 224, 1) !important;
  border-right: 1px solid rgba(224, 224, 224, 1) !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  border-top: 1px solid rgba(224, 224, 224, 1) !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.MuiDataGrid-root {
  border: None !important;
}
